<template>
  <div>
    <!-- Table Container Card -->
    <edit-permission
      :is-edit-permission-sidebar-active.sync="isEditPermissionSidebarActive"
      :permission="permissionRef"
      @refetch-data="refetchData"
      v-if="isEditPermissionSidebarActive"
    />
    <b-card no-body class="mb-0">
      <b-table
        ref="refPermissionListTable"
        class="position-relative"
        :items="fetchPermissions"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!----<b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="setPermission(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>
          </b-dropdown>-->
          <b-button
            @click="setPermission(data.item)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="EditIcon" size="16" />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPermissions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import userPermissionsList from "./usePermissionsList";
import { ref } from "@vue/composition-api";
import EditPermission from "./EditPermission.vue";

export default {
  components: {
    EditPermission,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },

  setup() {
    const isEditPermissionSidebarActive = ref(false);
    const permissionRef = ref({});

    const {
      fetchPermissions,
      tableColumns,
      perPage,
      currentPage,
      totalPermissions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermissionListTable,
      refetchData,
    } = userPermissionsList();

    function setPermission(permission) {
      isEditPermissionSidebarActive.value = true;
      permissionRef.value = permission;
    }

    return {
      // Sidebar
      isEditPermissionSidebarActive,

      fetchPermissions,
      tableColumns,
      perPage,
      currentPage,
      totalPermissions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermissionListTable,
      refetchData,
      // Filter
      avatarText,
      setPermission,
      permissionRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
